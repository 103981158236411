<template>
  <div>
      <div class="page-header">
        <h3 class="pt20">Privacy Policy</h3>
      </div>

    <section class="general-faq">
      <div class="container general-faq-cntnr">
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              General
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              This SERVICE is provided by KIPSLMS and is intended for use as is.
              This page is used to inform visitors regarding KIPSLMS policies
              with the collection, use, and disclosure of Personal Information
              if anyone decided to use KIPSLMS Service. If you choose to use
              KIPSLMS Service, then you agree to the collection and use of
              information in relation to this policy. The Personal Information
              that KIPSLMS collects is used for providing and improving the
              Service. KIPSLMS will not use or share your information with
              anyone except as described in this Privacy Policy.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Information Collection and Use
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to Name, Phone Number,
              Email, Registration Number, Campus, Class, Address. The app does
              use third party services that may collect information used to
              identify you.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Log Data
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              We want to inform you that whenever you use KIPSLMS Service, in a
              case of an error in the app, we collect data and information for
              error logging. This Log Data may include information such as your
              device Internet Protocol (“IP”) address, device name, operating
              system version, the configuration of the app when utilizing
              KIPSLMS Service, the time and date of your use of the Service, and
              other statistics.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="registration-faq">
      <div class="container registration-faq-cntnr">
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              Cookies
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory. This Service use these “cookies”
              explicitly. However, the app may use third party code and
              libraries that use “cookies” to collect information and improve
              their services.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Service Providers
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              We may employ third-party companies and individuals due to the
              following reasons:<br />
              <br />
              To facilitate our Service;<br />
              To provide the Service on our behalf;<br />
              To perform Service-related services; or<br />
              To assist us in analyzing how our Service is used.<br />
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Security
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="support-faq">
      <div class="container support-faq-cntnr">
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              Changes to This Privacy Policy
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Contact Us
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact us at Phone#: 042 111 547 775, Email:
              support@kipslms.com.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Privacy | KIPS LMS",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>

<style scoped>

</style>
